<template>
    <ModalWrapper custom-close ref="modal" transparent #default="{ close }">
        <div class="card-modal" :class="{ 'card-modal--short': short }">
            <div class="card-modal__close" @click="close">
                <AppIcon icon="close-small" size="24" />
            </div>
            <div class="card-modal__header">
                <div class="card-modal__info-row">
                    <div class="card-modal__logo">
                        <img :src="card.logo" alt="" />
                    </div>
                    <ProblemDescriptionItem
                        v-if="!short"
                        class="card-modal__heading"
                        name="components.common.balance"
                        :value="cardBalance + ' ' + currency"
                        nowrap
                        type="card-heading"
                    >
                        <template v-if="cardLoading" #value>
                            <div
                                class="placeholder placeholder--medium"
                                style="width: 266px"
                            ></div>
                        </template>
                    </ProblemDescriptionItem>
                    <ProblemDescriptionItem
                        class="card-modal__.card-modal__card-number"
                        name="components.common.cardNumber"
                        :value="card.cardNumber"
                        nowrap
                        type="card-heading"
                        copy-icon
                        mask-card
                    />
                    <ActiveOperationsButtons
                        :identification-data="identificationData"
                        :card="card"
                        @action="$emit('action', $event)"
                    />
                </div>
                <div class="card-modal__info-row card-modal__info-row--props">
                    <ProblemDescriptionItem
                        v-for="head in modalHead"
                        :class="'card-modal__info-' + head.key"
                        type="card"
                        :name="head.name"
                        :value="head.value"
                        :theme="head.theme"
                        :icon="head.icon"
                        nowrap
                    >
                        <template v-if="cardLoading" #value>
                            <div
                                class="placeholder placeholder--medium"
                                :style="{ width: head.width + 'px' }"
                            ></div>
                        </template>
                    </ProblemDescriptionItem>
                    <div
                        v-if="!short"
                        class="card-modal__search-message search-message search-message--normal"
                        :class="{
                            'search-message--theme-loading': cardLoading,
                            'search-message--theme-error': cardError,
                        }"
                    >
                        {{ textLoading }}
                        <LoadingIcon
                            v-if="cardLoading"
                            class="search-message__icon"
                        />
                        <div v-else class="search-message__icon round-icon">
                            <AppIcon
                                :icon="
                                    cardError ? 'close-small' : 'check-round'
                                "
                                size="10"
                            />
                        </div>
                    </div>
                    <div
                        v-if="processingTab !== 'sms'"
                        class="card-modal__filter"
                    >
                        <ProblemDescriptionItem
                            type="card"
                            name="components.common.displayPeriod"
                        >
                            <template #value>
                                <div
                                    class="block-wrap block-wrap--offset-macro flex-nowrap"
                                >
                                    <AppDatePicker
                                        class="card-modal__datepicker"
                                        v-model="filter.dateBegin"
                                        size="extra-large"
                                        value-format="DD.MM.YYYY"
                                        placeholder="components.common.startDate"
                                        @input="loadData"
                                    />
                                    <div class="align-self-center color-gray">
                                        —
                                    </div>
                                    <AppDatePicker
                                        class="card-modal__datepicker"
                                        v-model="filter.dateClose"
                                        size="extra-large"
                                        value-format="DD.MM.YYYY"
                                        placeholder="components.common.endDate"
                                        @input="loadData"
                                    />
                                </div>
                            </template>
                        </ProblemDescriptionItem>
                    </div>
                </div>
            </div>

            <div v-if="short">
                <TransactionsMobileTable
                    :filter="transactionsFilter"
                    :common-data="commonData"
                    :is-chat="isChat"
                    @close="close"
                    @reset="onTransactionsReset"
                />
            </div>
            <AppTabs
                v-else
                :value="processingTab"
                :items="processingTabs"
                theme="processing"
                @change="selectProcessingTab"
            >
                <template #body.bank>
                    <AppTable
                        v-if="bankLoading"
                        key="a"
                        :head="bankHead"
                        :data="skeletonBank"
                        theme="card-disabled"
                    >
                        <template #cell.id
                            ><div class="placeholder placeholder--macro"></div
                        ></template>
                        <template #cell.date
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.amount
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.type
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.ctAccName
                            ><div class="placeholder placeholder--large"></div
                        ></template>
                        <template #cell.purpose
                            ><div class="placeholder placeholder--large"></div
                        ></template>
                    </AppTable>
                    <div
                        v-else-if="!bankTransactionsData.length"
                        class="card-modal__description"
                    >
                        {{ $t("components.common.nothingFound") }}
                    </div>
                    <AppTable
                        v-else
                        key="b"
                        :head="bankHead"
                        :data="parsedData"
                        theme="card"
                    >
                        <template #cell.id="{ rowIndex }">{{
                            rowIndex + 1 + limit * (page - 1)
                        }}</template>
                        <template #cell.type="{ item }">
                            {{
                                isCredit(item)
                                    ? $t("components.common.accrual")
                                    : $t("components.common.write-Off")
                            }}
                        </template>
                        <template #cell.amount="{ item }">
                            <div
                                :class="
                                    isCredit(item)
                                        ? 'color-success'
                                        : 'color-danger'
                                "
                            >
                                {{
                                    formatNumber(
                                        isCredit(item)
                                            ? item.credit
                                            : item.debit,
                                        true
                                    )
                                }}
                            </div>
                        </template>
                    </AppTable>
                    <div v-if="bankLoading" class="card-modal__pagination">
                        <div class="placeholder placeholder--small"></div>
                    </div>
                    <div
                        v-else-if="count > limit"
                        class="card-modal__pagination"
                    >
                        <b-pagination
                            class="m-0"
                            next-class="prev"
                            prev-class="next"
                            prev-text=""
                            next-text=""
                            v-model="page"
                            :total-rows="count"
                            :per-page="limit"
                        />
                    </div>
                </template>
                <template #body.processing>
                    <AppTable
                        v-if="processingLoading"
                        key="a"
                        :head="processingHead"
                        :data="skeletonBank"
                        theme="card-disabled"
                    >
                        <template #cell.id
                            ><div class="placeholder placeholder--macro"></div
                        ></template>
                        <template #cell.date
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.amount
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.type
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.merchantName
                            ><div class="placeholder placeholder--large"></div
                        ></template>
                        <template #cell.city
                            ><div class="placeholder placeholder--small"></div
                        ></template>
                        <template #cell.typeDesc
                            ><div class="placeholder placeholder--large"></div
                        ></template>
                    </AppTable>
                    <div
                        v-else-if="!processingData.length"
                        class="card-modal__description"
                    >
                        {{ $t("components.common.nothingFound") }}
                    </div>
                    <AppTable
                        v-else
                        :head="processingHead"
                        :data="parsedData"
                        theme="card"
                    >
                        <template #cell.id="{ item }">
                            {{ item.utrnno }}</template
                        >
                        <template #cell.date="{ item }">{{
                            dateFormatter(item.udate) + " " + item.utime
                        }}</template>
                        <template #cell.amount="{ item }">
                            <div
                                :class="
                                    item.isCredit
                                        ? 'color-success'
                                        : 'color-danger'
                                "
                            >
                                {{ formatNumber(item.reqamt / 100, true) }}
                                {{ item.currency }}
                            </div>
                        </template>
                        <template #cell.type="{ item }">
                            {{
                                item.isCredit
                                    ? $t("components.common.accrual")
                                    : $t("components.common.write-Off")
                            }}
                        </template>
                        <template #cell.city="{ item }">
                            {{ parseCity(item.city, item.street) }}
                        </template>
                        <template #cell.typeDesc="{ item }">
                            {{ item.typeDesc || "-" }}
                        </template>
                    </AppTable>
                    <div
                        v-if="processingLoading"
                        class="card-modal__pagination"
                    >
                        <div class="placeholder placeholder--small"></div>
                    </div>
                    <div
                        v-else-if="count > limit"
                        class="card-modal__pagination"
                    >
                        <b-pagination
                            class="m-0"
                            next-class="prev"
                            prev-class="next"
                            prev-text=""
                            next-text=""
                            v-model="page"
                            :total-rows="count"
                            :per-page="limit"
                        />
                    </div>
                </template>
                <template #body.sms>
                    <form
                        class="sms-info-card application-input-theme-problem"
                        @submit.prevent="activeOperationsSubmit"
                    >
                        <div class="default-heading mb-5-a">
                            {{ $t("components.common.smsNotificationNumber") }}
                        </div>
                        <div class="application-input-wrap mb-5-a">
                            <div class="application-input-title">
                                {{ $t("components.common.chooseAnAction") }}
                            </div>
                            <AppSelect
                                v-model="activeOperationsType_"
                                class="application-input"
                                size="large"
                                :items="smsCardOperationsLists"
                                item-name="name"
                                item-value="value"
                            />
                        </div>
                        <div class="application-input-wrap mb-6-a">
                            <div class="application-input-title">
                                {{ $t("components.common.chooseAnAction") }}
                            </div>
                            <div class="prefix-input" style="--prefix: 54px">
                                <div class="prefix-input__prefix">+998</div>
                                <AppInput
                                    v-model="activeOperationsModel.phone"
                                    class="prefix-input__input"
                                    :disabled="
                                        activeOperationsType_ ===
                                        activeOperationsCodes.nosms
                                    "
                                    :error="activeOperationsErrors.phone"
                                    v-mask="phoneMask"
                                    size="large"
                                    @input="activeOperationsOnInput('phone')"
                                />
                            </div>
                            <div
                                v-if="activeOperationsErrors.phone"
                                class="application-input-error"
                            >
                                {{
                                    $t("components.common.thisFieldIsRequired")
                                }}
                            </div>
                        </div>
                        <div class="d-flex">
                            <AppButton
                                class="pl-6-a pr-6-a mr-4-a"
                                type="button"
                                theme="gray"
                                tabindex="-1"
                                @click="close"
                            >
                                {{ $t("components.common.сancel") }}
                            </AppButton>
                            <LoadingContent :loading="activeOperationsLoading">
                                <AppButton
                                    class="pl-6-a pr-6-a"
                                    type="submit"
                                    :theme="
                                        activeOperationsSent
                                            ? 'success-flat'
                                            : 'primary'
                                    "
                                >
                                    {{
                                        activeOperationsSent
                                            ? $t("components.common.saved")
                                            : $t("components.common.save")
                                    }}
                                </AppButton>
                            </LoadingContent>
                        </div>
                    </form>
                </template>
            </AppTabs>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "./partials/ModalWrapper";
import AppTable from "./partials/AppTable";
import PageLoader from "./partials/PageLoader";
import { get } from "../helpers/api";
import ListUtils from "../mixins/ListUtils";
import ProblemDescriptionItem from "./partials/ProblemDescriptionItem";
import AppDatePicker from "./partials/AppDatePicker";
import AppTabs from "./partials/AppTabs";
import AppButton from "./partials/AppButton";
import AppIcon from "./partials/AppIcon";
import LoadingIcon from "./partials/LoadingIcon";
import ActiveOperationsButtons from "./partials/pages/ActiveOperationsButtons";
import AppSelect from "./partials/AppSelect";
import AppInput from "./partials/AppInput";
import LoadingContent from "./partials/LoadingContent";
import activeOperationsMixin from "../mixins/activeOperationsMixin";
import { activeOperationsCodes } from "../store/modules/activeOperations";
import TransactionsMobileTable from "./partials/pages/monitoring/TransactionsMobileTable";

const loadingDelay = 400;

export default {
    name: "CardModal",
    components: {
        TransactionsMobileTable,
        LoadingContent,
        AppInput,
        AppSelect,
        ActiveOperationsButtons,
        LoadingIcon,
        AppIcon,
        AppButton,
        AppTabs,
        AppDatePicker,
        ProblemDescriptionItem,
        PageLoader,
        AppTable,
        ModalWrapper,
    },
    mixins: [ListUtils, activeOperationsMixin],
    props: {
        card: Object,
        cardLoading: Boolean,
        cardError: Boolean,
        short: Boolean,
        identificationData: {
            type: Object,
        },
        userData: {
            type: Object,
            required: true,
        },
        commonData: Object,
        isChat: Boolean,
    },
    data() {
        const intl = new Intl.DateTimeFormat(["ru-RU"], {});
        let dateBegin = new Date();

        if (this.card.type.toLowerCase() !== "uzcard") {
            dateBegin.setMonth(dateBegin.getMonth() - 1);
            dateBegin = intl.format(dateBegin);
        } else {
            dateBegin = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)
                .toLocaleDateString("en-GB")
                .split("/")
                .join(".");
        }
        let dateClose = intl.format(new Date());

        return {
            processingLoading: false,
            bankLoading: false,
            page: 1,
            count: 0,
            limit: 20,
            bankHead: [
                [
                    {
                        name: "№",
                        key: "id",
                    },
                    {
                        name: "Дата и время",
                        key: "date",
                    },
                    {
                        name: "Сумма",
                        key: "amount",
                    },
                    {
                        name: "Тип транзакции",
                        key: "type",
                    },
                    {
                        name: "Название продавца",
                        key: "ctAccName",
                    },
                    {
                        name: "Описание транзакции",
                        key: "purpose",
                    },
                ],
            ],
            processingHead: [
                [
                    {
                        name: "№",
                        key: "id",
                    },
                    {
                        name: "Дата и время",
                        key: "date",
                    },
                    {
                        name: "Сумма",
                        key: "amount",
                    },
                    {
                        name: "Тип транзакции",
                        key: "type",
                    },
                    {
                        name: "Название продавца",
                        key: "merchantName",
                    },
                    {
                        name: "Город продавца",
                        key: "city",
                    },
                    {
                        name: "Описание транзакции",
                        key: "typeDesc",
                    },
                ],
            ],
            defaultFilter: {
                dateBegin,
                dateClose,
            },
            filter: {
                dateBegin,
                dateClose,
            },
            processingTab: "processing",
            bankTransactionsData: [],
            processingData: [],
            skeletonBank: new Array(10).fill(0),

            phoneMask: "## ### ## ##",
            activeOperationsType_: activeOperationsCodes.sms,
        };
    },
    computed: {
        transactionsFilter() {
            return {
                dateFrom: this.filter.dateBegin,
                dateTo: this.filter.dateClose,
                id: this.card.cardId,
                where: "card",
            };
        },
        modalHead() {
            if (this.short) {
                return [
                    {
                        key: "number-icon",
                        width: 98,
                        name: "Номер карты",
                        value: this.card.cardNumberMasked,
                        icon: "credit-card-front",
                    },
                    {
                        key: "state-icon",
                        width: 98,
                        name: "Состояние карты",
                        value: this.card.stateName,
                        icon: "pulse",
                    },
                    {
                        key: "state",
                        width: 98,
                        name: "Владелец",
                        value: this.card.cardHolder,
                        icon: "user-circle-outline",
                    },
                ];
            }
            return [
                {
                    key: "state",
                    width: 98,
                    name: "Состояние",
                    value: this.card.stateName,
                    theme: this.card.state === "active" ? "success" : "danger",
                },
                {
                    key: "sms",
                    width: 219,
                    name: "SMS-информирование",
                    value: this.card.sms
                        ? "Подключено (" + this.card.phone + ")"
                        : "Отключено",
                },
                {
                    key: "valid",
                    width: 171,
                    name: "Срок действия",
                    value: this.card.valid,
                },
                {
                    key: "pin",
                    width: 122,
                    name: "Ошибок ввода PIN",
                    value: this.card.pincnt,
                },
                {
                    key: "code",
                    width: 87,
                    name: "Код филиала",
                    value: this.card.codeFilial,
                },
                {
                    key: "account",
                    width: 222,
                    name: "Счёт",
                    value: this.card.cardAccount,
                },
            ];
        },
        textLoading() {
            return this.$t(
                this.cardLoading
                    ? "components.common.clarificationBegin"
                    : this.cardError
                    ? "errors.failedClarification"
                    : "components.common.dataSuccessDownload"
            );
        },
        cardBalance() {
            const balance = this.card.balance || this.card.cardBalance || 0;
            return this.formatNumber(balance / 100, true);
        },
        currency() {
            return this.getCurrency(this.card.currencyCode);
        },
        parsedData() {
            const data =
                this.processingTab === "processing"
                    ? this.processingData
                    : this.bankTransactionsData;
            return data.slice(
                (this.page - 1) * this.limit,
                this.page * this.limit
            );
        },
        processingTabs() {
            const tabs = [
                {
                    key: "processing",
                    name: "Процессинг",
                },
                {
                    key: "bank",
                    name: "Банк",
                },
            ];
            if (this.activeOperationsEnabled) {
                tabs.push({
                    key: "sms",
                    name: "СМС информирование",
                });
            }
            return tabs;
        },
        smsCardOperationsLists() {
            return [
                {
                    name: "Изменить номер СМС информирования",
                    value: this.activeOperationsCodes.sms,
                },
                {
                    name: "Отключить СМС информирования",
                    value: this.activeOperationsCodes.nosms,
                },
            ];
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        parseCity(city, street) {
            return `${city.trim() ? city + ", " : ""}${street}`;
        },
        loadData() {
            if (this.short) {
                return;
            } else if (this.processingTab === "processing") {
                this.loadProcessingData();
            } else {
                this.loadBankData();
            }
        },
        selectProcessingTab(tab) {
            this.processingTab = tab;
            this.loadData();
        },
        isCredit(item) {
            return item.debit == "0";
        },
        loadProcessingData() {
            this.processingLoading = true;
            this.processingData = [];
            this.page = 1;
            this.count = 0;
            const type = this.card.type.toLowerCase();

            get(
                `/processing/${type}-history?card=${this.card.cardNumber}&valid=${this.card.dateExpiry}&dateStart=${this.filter.dateBegin}&dateEnd=${this.filter.dateClose}`
            )
                .then((res) => {
                    if (res.data.success) {
                        this.processingData = res.data.data;
                        this.count = res.data.data.length;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.processingLoading = false;
                    }, loadingDelay);
                });
        },
        loadBankData() {
            const filter = Object.keys(this.filter).reduce((filter, key) => {
                if (this.filter[key])
                    return `${filter}&${key}=${this.filter[key]}`;
                else return filter;
            }, "");
            this.bankLoading = true;
            this.bankTransactionsData = [];

            get(
                `/aibs/account-history-by-num/${this.card.cardAccount}?clientUid=${this.userData.bank_client_id}${filter}&sort=date`
            )
                .then((res) => {
                    if (res.data.success) {
                        this.bankTransactionsData = res.data.data;
                        this.count = res.data.data.length;
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.bankLoading = false;
                    }, loadingDelay);
                });
        },
        onTransactionsReset() {
            this.filter = { ...this.defaultFilter };
        },
        dateFormatter(date) {
            return date.split("-").join(".");
        },
    },
};
</script>
<style lang="scss">
@import "../assets/scss/variables";
.card-modal {
    $self: &;
    background: var(--color-white);
    border-radius: 12px;
    width: 1640px;
    max-width: 100%;
    font-family: $font-secondary;
    padding-bottom: 36px;
    position: relative;
    &__close {
        cursor: pointer;
        user-select: none;
        position: absolute;
        padding: 16px;
        top: 0;
        right: 0;
    }
    &__header {
        padding: 36px 42px 36px;
    }
    &__info-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 36px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__datepicker {
        width: 192px;
    }
    &__filter {
        margin-left: auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 3px;
        margin-bottom: -74px;
    }
    &__info {
        &-state {
            min-width: 145px;
            padding-right: 20px;
        }
        &-sms {
            min-width: 291px;
        }
        &-pin {
            min-width: 195px;
        }
        &-code {
            min-width: 159px;
        }

        &-number-icon {
            min-width: 259px;
        }
        &-state-icon {
            min-width: 238px;
        }
        &-valid {
            min-width: 171px;
        }
    }
    &__search-message {
        margin-left: auto;
    }
    &__logo {
        margin-right: 26px;
        width: 72px;
        height: 48px;
        background: #edf2ff;
        border-radius: 8px;
        padding: 8px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__card-number {
        // width: 354px;
    }
    &__heading {
        min-width: 338px;
        padding-right: 20px;
    }
    &__description {
        padding-left: 42px;
        padding-right: 42px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
    &__pagination {
        display: flex;
        justify-content: flex-end;
        padding-right: 42px;
        margin-top: 22px;
    }
    &--short {
        #{$self}__heading {
            min-width: 399px;
        }
        #{$self}__filter {
            width: auto;
            padding-top: 0;
            margin-bottom: 0;
            margin-top: -5px;
        }
        #{$self}__info-row {
            margin-bottom: 0;
            &--props {
                align-items: flex-end;
            }
        }
        #{$self}__header {
            padding-bottom: 32px;
        }
    }

    .active-operations-buttons {
        margin-left: 12px;
    }
}
.round-icon {
    border-radius: 100%;
    background-color: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        color: #fff;
    }
}
.sms-info-card {
    max-width: 458px;
    padding-left: 42px;
}
.prefix-input {
    position: relative;
    width: 100%;
    &__prefix {
        width: var(--prefix);
        z-index: 1;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        text-align: right;
    }
    &__input {
        width: 100%;
        padding-left: calc(var(--prefix) + 4px);
    }
}
</style>
